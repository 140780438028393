.event-bar {
  margin: 0;
  background-color: #2d2d2d;
  box-shadow: rgba(0, 0, 0, 0.58) 0px 0px 10px 0px;
  padding: 0 10px 18px 10px;

  height: 100vh;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (max-width: 899px) {
  .event-bar {
    display: none;
  }
}

.event-badge {
  border-radius: 50%;
  background-color: red;
  width: 10px;
  height: 10px;
}


.event-badge__hide {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 0s;
}

.event-badge__displayed {
  opacity: 1;
}

.event-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-indicator__label {
  font-size: 9px;
  margin-bottom: 5px;
  font-family: Roboto Condensed,sans-serif;
}

.analytics-checker__button {
  cursor: pointer;
  font-family: Roboto Condensed,sans-serif;
  border: none;
  border-radius: 5px;
  padding: 5px;

  background-color: white;
}

.analytics-checker__button:hover {
  background-color: #f1f1f1;
}

.analytics-checker__button:active {
  background-color: #d0d0d0;
}

.debug-mode__label {
  color: white;
  font-size: 9px;
  margin-bottom: 5px;
  font-family: Roboto Condensed,sans-serif;
}

.what-is-this__container {
  display: flex;
  justify-content: center;
}

.what-is-this__badge {
  color: white;
  text-align: center;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 50%;
  cursor: help;
  width: 19px;
  height: 19px;
}
