body {
  background-image: url(https://idp-demo.uefa.com/assets/modal_bg_img.png);
  background-repeat: repeat-x;
}

.App-main {
  text-align: center;
}

.App-header {
  text-align: left;
  background-image: radial-gradient(circle, white, rgba(192, 192, 192, 1));
  box-shadow: rgba(0, 0, 0, 0.58) 0px 0px 10px 0px;
  height: 40px;
  padding: 0 20px;
  color: white;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

}

.App-header h1 {
  margin: 0;
}

.App-title {
  font-size: 18px;
  color: #333333;
}

.App-title-user {
  font-size: 16px;
  color: #333333;
}

.App-intro {
  font-size: large;
}

.event-container {
  width: 100%;
  max-width: 1024px;
  margin: auto;

  text-align: center;
}

@media screen and (max-width: 899px) {
  .user-data,
  .event-container {
    display: none;
  }
}

table.event-table {
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  background-color: white;
  border-collapse: collapse;
}

table.event-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

table.event-table th,
table.event-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

table.event-table th {
  vertical-align: bottom;
  color: #fff;
  background-color: #afb6bc;
  border: none;
}

pre {
  text-align: left;
  margin: auto;
  max-width: 600px;
  overflow: scroll;
}

pre {
  text-align: left;
}

.postRegistrationUrl-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.postRegistrationUrl-title {
  padding: 20px;
}

.state__up > svg {
  color: #3e9452;
}

.state__down > svg {
  color: #9e1c27;
}

.service__swagger {
  margin-left: 8px !important;
}
