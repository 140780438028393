.screen-title {
  flex-grow: 1;
}

#idp-gigya-embedded-screen-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 64px 0px 48px;
}

#idp-gigya-embedded-screen-container .gigya-screen-caption {
  text-align: center;
}
