.ReactModal__Overlay {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0,0,0,.5);
}

.ReactModal__Content {
	top: 5px;
	width: auto;
	height: 100%;
	background-color: white;
	position: relative;
}

.login__modal {
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

@media screen and (min-width: 768px) {
	.login__modal {
		width: auto;
	}
}

@media screen and (min-width: 960px) {
	.login__modal {
		box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		overflow: hidden;
		flex-direction: row;
		min-height: 680px;
		max-height: 90vh;
		width: 850px;
		height: auto;
	}
}


/* IDP Login plugin container */

.login-modal__login-container {
	box-sizing: border-box;
	background-color: white;
	width:auto;
	/* min-height: 660px; */
}

@media screen and (min-width: 960px) {
	.login-modal__login-container {
		overflow-x: hidden;
		overflow-y: auto;
		width: 50%;
	}
}

.login-modal__login-container .gigya-screen-caption {
	text-align: center;
}

.login-modal__login-container .gigya-screen.landscape,
  .login-modal__login-container .gigya-screen.portrait {
	padding-top: 20px !important;
}

/* Site host free container */

.login-modal__text-container {
	background: lightskyblue;
	font-family: 'UEFAPlaystrong-regular';
	float:none;
}

@media screen and (min-width: 960px) {
	.login-modal__text-container {
		float:left;
		width: 50%;
	}
}

.login-modal__text-container-inner {
	padding: 24px;
}

.login-modal__title {
	font-size: 28px;
	line-height: 32px;
	text-transform: uppercase;
	font-family: 'UEFAPlaystrong-medium';
	margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
	.login-modal__title {
		margin-bottom: 32px;
	}
}

.login-modal__image {
	position: relative;
	width: 55px;
	height: 48px;
	flex-shrink: 0;
	margin-right: 32px;
}

@media screen and (min-width: 768px) {
	.login-modal__image {
		margin-bottom: 20px;
	}
}

.login-modal__image:before {
	content: '🏆';
	position: absolute;
	font-size: 37px;
	width: 37px;
	height: 42px;
	top: 0;
	left: 0;
}

.login-modal__image:after {
	content: '😍';
	position: absolute;
	font-size: 27px;
	width: 27px;
	height: 31px;
	right: 0;
	bottom: 0;
}

.login-modal__image--second:before {
	content: '🥅';
}

.login-modal__image--second:after {
	content: '⚽';
}

.login-modal__text {
	display: flex;
	margin-bottom: 12px;
	z-index: 1;
}

@media screen and (min-width: 768px) {
	.login-modal__text {
		flex-direction: column;
		margin-bottom: 32px;
	}
}

.login-modal__text p {
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: black;
	margin: 0;
	width: 100%;
}

@media screen and (min-width: 768px) {
	.login-modal__text p {
		width: 50%;
	}
}

@media screen and (min-width: 960px) {
	.login-modal__text p {
		width: 100%;
	}
}

/* Close Icon */

.ReactModal__Content .close {
	position: absolute;
	top: 0;
	right: 0;
	margin: 18px;
	border: none;
	background-color: transparent;
	outline: none;
	z-index: 2;
}

.ReactModal__Content .close:after, .ReactModal__Content .close:before {
	background-color: lightgray;
}

.login__modal .closeIcon {
	top: 7px;
}

@media screen and (min-width: 960px) {
	.login__modal .closeIcon {
		top: 0;
	}
}

.login__modal .closeIcon:before, .login__modal .closeIcon:after {
	background-color: lightgray;
}

@media screen and (min-width: 960px) {
	.login__modal .closeIcon:before, .login__modal .closeIcon:after {
		background-color: lightgray;
	}
}

.closeIcon {
	cursor: pointer;
	position: relative;
	width: 20px;
	height: 20px;
}

.closeIcon:before, .closeIcon:after {
	content: '';
	position: absolute;
	left: 5px;
	top: 3px;
	height: 20px;
	width: 3px;
	background-color: #3c3c3c;
}

.closeIcon:hover:before, .closeIcon:hover:after {
	background-color: gray;
}

.closeIcon:before {
	transform: rotate(45deg);
}

.closeIcon:after {
	transform: rotate(-45deg);
}
